<template>
    <el-table class="zj-table" :data="tableData" :show-header="false" :span-method="arraySpanMethod">
        <el-table-column prop="name" label="">
            <template slot-scope="scope">
                <div v-html="scope.row.name"></div>
            </template>
        </el-table-column>
        <el-table-column prop="data" label="" align="right">
            <template slot-scope="scope">
                <div v-html="scope.row.data"></div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    name:"estable",
    props:["partInfos","isHushenFont"],
    data () {
        return {
            esinfo: this.partInfos,
            tableData: []
        }
    },
    watch:{
        partInfos(){
            this.esinfo = this.partInfos;
            let table_data = [
                {
                    name:`<span class="bold">波动率比较</span><br/>
                        <span>截止${this.partInfos.realized_vol_change_table.TradingDay[0].replace(/-/g,'/').substr(0,10)}</span>`,
                    data: ''
                },
                {
                    name:'<span>波动率 (动态)</span>',
                    data:this.toPercent(this.partInfos.realized_vol_change_table.dynamic)
                } ,
                {
                    name:'<span>变化</span>',
                    data:this.toPercent(this.partInfos.realized_vol_change_table.dynamic_change, true)
                } ,
                {
                    name:'<span>波动率 (滚动)</span>',
                    data:this.toPercent(this.partInfos.realized_vol_change_table.rolling)
                } ,
                {
                    name:'<span>变化</span>',
                    data:this.toPercent(this.partInfos.realized_vol_change_table.rolling_change, true)
                }
            ] ;
            this.tableData = table_data                
        }
    },
    mounted(){
        this.esinfo = this.partInfos;
    },
    methods: {
        arraySpanMethod({rowIndex, columnIndex}) {
            if (rowIndex === 0) {
                if (columnIndex === 0) {
                    return [1, 2];
                } else {
                    return [0, 0];
                }
            }
        },
        toPercent(point, withColor){
            var str = Number(point*100).toFixed(2) + '%'
            if (!withColor) {
                return `<span style="font-weight:600;" class="arrow-trans">${str}</span>`
            }
            // 沪深指数 正是红色，负数是绿色，其余指数相反
            let isHushenFont = this.isHushenFont
            if (point>0) {
                if (isHushenFont) {
                    return `<span style="color:#E64545;font-weight:600;" class="arrow-up">${str}</span>`
                } else {
                    return `<span style="color:#67B35A;font-weight:600;" class="arrow-up">${str}</span>`
                }
            } else {
                if (isHushenFont) {
                    return `<span style="color:#67B35A;font-weight:600;" class="arrow-down">${str}</span>`
                } else {
                    return `<span style="color:#E64545;font-weight:600;" class="arrow-down">${str}</span>`
                }
            }
        }
    },
}
</script>

<style lang="less" scoped>
.zj-table{
    background-color: transparent;
    /deep/ .bold{
        font-weight: bold;
    }
    &.el-table /deep/ th, &.el-table /deep/ tr{
        background-color: transparent;
        font-size: 12px;
    }
    &.el-table /deep/ td{
        padding: 12px 0;
        border-bottom: 1px solid #ccc;
        &:first-child{
            padding-left: 5px;
        }
        // &:last-child{
        //     padding-right: 5px;
        // }
        span{
            word-break: keep-all;
        }
    }
}
</style>