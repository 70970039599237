<template>
    <div id="mychart" ref="mychart" :style="{width: '100%', height: '440px'}"></div>
</template>

<script>
export default {
    name: 'realizedVolEcharts',   
    data () {
        return {
            myChart:{},
            params: {},                    
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.setEchart ();
        })
    },
    computed: {
        option: function() {
            return this.$store.state.realizedvolecharts.option;
        },         
    },        
    watch: {
        //观察option的变化
        option: {
            handler(newVal, oldVal) {
                if (this.myChart) {
                    if (newVal) {
                        this.myChart.setOption(newVal,true);
                    } else {
                        this.myChart.setOption(oldVal,true);
                    }
                } else {
                    this.setEchart();
                }
            },
            deep: true //对象内部属性的监听，关键。
        }         
    },
    methods: {
        setEchart () {
            let dom = this.$refs.mychart;
            this.myChart = this.$echarts.init(dom);
        }, 
        changeData (params) {
            this.params = params
            this.$store.dispatch('realizedvolecharts/getIndexRealizedVolsChart',this.params).then(res=>{
                this.$emit("callBackInfo", res.data)
            })
            
        },  
    }
}
</script>